.site-footer {
    background-color: #1a202c;
    color: #e2e8f0;
    padding: 60px 0 30px;
    font-family: 'Arial', sans-serif;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  
  .footer-section {
    flex: 1;
    margin-bottom: 30px;
    min-width: 250px;
  }
  
  .footer-section h3 {
    font-size: 1.4rem;
    margin-bottom: 20px;
    color: #4299e1;
    font-weight: 600;
  }
  
  .footer-section p {
    line-height: 1.6;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 12px;
  }
  
  .footer-section a {
    color: #e2e8f0;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section a:hover {
    color: #4299e1;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #2d3748;
    color: #e2e8f0;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    pointer-events: auto;
  }
  
  .social-icon:hover {
    transform: translateY(-3px);
  }
  
  .social-icon.linkedin:hover { background-color: #0077b5; }
  .social-icon.twitter:hover { background-color: #1da1f2; }
  .social-icon.facebook:hover { background-color: #1877f2; }
  .social-icon.instagram:hover { background-color: #e1306c; }
  
  .footer-bottom {
    max-width: 1200px;
    margin: 30px auto 0;
    padding: 20px 20px 0;
    border-top: 1px solid #2d3748;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .legal-links a {
    color: #a0aec0;
    text-decoration: none;
    margin-left: 20px;
    transition: color 0.3s ease;
  }
  
  .legal-links a:hover {
    color: #4299e1;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
    
    .footer-bottom {
      flex-direction: column;
      text-align: center;
    }
    
    .legal-links {
      margin-top: 15px;
    }
    
    .legal-links a {
      margin: 0 10px;
    }
  }