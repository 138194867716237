.ms-features-section {
    padding: 120px 0;
    background: linear-gradient(135deg, #f5f5f5 0%, #ffffff 100%);
    overflow: hidden;
    position: relative;
  }
  
  .ms-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
  }
  
  .ms-section-title {
    font-size: 3.5rem !important;
    font-weight: 700 !important;
    color: #000 !important;
    margin-bottom: 24px !important;
    line-height: 1.2 !important;
  }
  
  .ms-highlight {
    color: #0078d4;
    position: relative;
    display: inline-block;
  }
  
  .ms-highlight::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: rgba(0, 120, 212, 0.1);
    z-index: -1;
    transform: skew(-15deg);
  }
  
  .ms-section-description {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 40px !important;
    max-width: 600px;
  }
  
  .ms-features-collapse {
    background: transparent;
    border: none;
  }
  
  .ms-features-collapse .ant-collapse-item {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 16px;
  }
  
  .ms-features-collapse .ant-collapse-header {
    padding: 16px 0;
  }
  
  .ms-feature-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .ms-feature-title .anticon {
    margin-right: 12px;
    font-size: 1.5rem;
    color: #0078d4;
  }
  
  .ms-features-collapse .ant-collapse-content-box {
    padding: 0 0 16px;
  }
  
  .ms-features-image {
    position: relative;
    z-index: 2;
  }
  
  .ms-features-image img {
    max-width: 100%;
    height: auto;
    border-radius: 16px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  }
  
  .ms-features-image:hover img {
    transform: translateY(-10px) rotate(2deg);
  }
  
  .ms-background-shape {
    position: absolute;
    top: -10%;
    left: -5%;
    width: 40%;
    height: 70%;
    background: linear-gradient(45deg, rgba(0, 120, 212, 0.1) 0%, rgba(0, 120, 212, 0.05) 100%);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    z-index: 1;
    animation: morphShape 15s ease-in-out infinite;
  }
  
  @keyframes morphShape {
    0% {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
    50% {
      border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
    }
    100% {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
  }
  
  @media (max-width: 992px) {
    .ms-features-section {
      padding: 80px 0;
    }
  
    .ms-section-title {
      font-size: 2.75rem !important;
    }
  }
  
  @media (max-width: 768px) {
    .ms-section-title {
      font-size: 2.25rem !important;
    }
  
    .ms-section-description {
      font-size: 1.1rem;
    }
  
    .ms-features-image {
      margin-top: 40px;
    }
  }