:root {
    --primary-color: #6e8efb;
    --secondary-color: #a777e3;
    --text-color: #333;
    --light-bg: #f8f9fa;
    --transition: all 0.3s ease;
  }
  
  body {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .about-company {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 40px;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    padding: 120px 20px;
    border-radius: 0 0 50% 50% / 20px;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: 800;
    letter-spacing: -1px;
  }
  
  .hero p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
    opacity: 0.9;
  }
  
  section {
    margin-bottom: 100px;
  }
  
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    position: relative;
  }
  
  h2::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: var(--primary-color);
    margin: 20px auto 0;
  }
  
  .mission-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    transition: var(--transition);
  }
  
  .mission-image:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
  
  .value-card, .team-card, .stat-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: var(--transition);
    border: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }
  
  .value-card:hover, .team-card:hover, .stat-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  .value-icon {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .team-card .ant-avatar {
    margin-bottom: 15px;
    border: 4px solid var(--primary-color);
  }
  
  .team-card h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  
  .team-card .role {
    color: #666;
    font-style: italic;
  }
  
  .team-card .statement {
    font-size: 0.9rem;
    color: #555;
    font-style: italic;
    margin-top: 15px;
  }
  
  .stats {
    background-color: var(--light-bg);
    border-radius: 10px;
    padding: 60px 40px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  .stats .ant-statistic-title {
    font-size: 14px;
    color: #666;
  }
  
  .stats .ant-statistic-content {
    font-size: 24px;
    color: var(--primary-color);
  }
  
  .milestones {
    padding: 60px 0;
    background-color: #f8f9fa;
  }

  .timeline-container {
    position: relative;
    overflow-x: auto;
    padding: 20px 0;
  }

  .timeline-track {
    display: flex;
    min-width: max-content;
    padding-bottom: 30px;
  }

  .timeline-track::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    background: var(--primary-color);
    transform: translateY(-50%);
  }

  .timeline-item {
    flex: 0 0 200px;
    margin-right: 20px;
    position: relative;
    text-align: center;
  }

  .timeline-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .timeline-content:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }

  .timeline-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .timeline-content h3 {
    font-size: 1.2rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }

  .timeline-content p {
    font-size: 1rem;
    margin: 0;
  }

  .timeline-point {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary-color);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .timeline-point::before {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .timeline-item:hover .timeline-point::before {
    opacity: 1;
    width: 50px;
    height: 50px;
  }

  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2rem;
    }

    .hero p {
      font-size: 1rem;
    }

    .value-card,
    .team-card,
    .stat-card {
      margin-bottom: 16px;
    }

    .stats .ant-statistic-title {
      font-size: 12px;
    }

    .stats .ant-statistic-content {
      font-size: 18px;
    }

    .timeline-item {
      flex: 0 0 150px;
    }
  }

  @media (max-width: 576px) {
    .about-company {
      padding: 10px;
    }

    .hero h1 {
      font-size: 1.8rem;
    }

    .value-card,
    .team-card,
    .stat-card {
      padding: 10px;
    }

    .timeline-item {
      flex: 0 0 120px;
    }
  }