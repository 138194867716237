.profile-page {
  background-color: #f0f2f5;
  min-height: 100vh;
}

.profile-header {
  background-color: #fff;
  padding: 16px 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-content {
  max-width: 1200px;
  margin: 24px auto;
  padding: 0 24px;
}

.profile-card {
  border-radius: 8px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.user-info-card .profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px 0;
}

.user-info-card .ant-avatar {
  margin-bottom: 16px;
}

.info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.info-icon {
  font-size: 24px;
  margin-right: 16px;
  color: #1890ff;
}

.ant-tabs-nav {
  margin-bottom: 24px;
}

.ant-statistic {
  text-align: center;
}

@media (max-width: 768px) {
  .profile-content {
    padding: 0 16px;
  }
}