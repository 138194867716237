.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.signup-box {
  background: white;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 480px;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 150px;
}

h1 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-select-selector {
  border-radius: 4px !important;
}

.ant-btn-primary {
  background-color: #0078d4;
  border-color: #0078d4;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
}

.ant-btn-primary:hover {
  background-color: #106ebe;
  border-color: #106ebe;
}

.error-alert {
  margin-top: 20px;
}

.signup-footer {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  color: #666;
}

.signup-link {
  color: #0078d4;
}

.signup-message {
  text-align: center;
  margin-bottom: 20px;
  color: #666;
}

.brand-highlight {
  color: #0078d4;
  font-weight: bold;
}

@media (max-width: 576px) {
  .signup-box {
    padding: 30px 20px;
  }

  h1 {
    font-size: 20px;
  }
}