.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
  
  .forgot-password-box {
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 40px;
    width: 100%;
    max-width: 400px;
  }
  
  h1 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .forgot-password-footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .forgot-password-footer a {
    color: #4a90e2;
  }