.ms-subscribe-section {
  padding: 120px 0;
  background: linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%);
  overflow: hidden;
  position: relative;
}

.ms-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  z-index: 2;
}

.ms-section-title {
  font-size: 3.5rem !important;
  font-weight: 700 !important;
  text-align: center;
  color: #000 !important;
  margin-bottom: 24px !important;
  line-height: 1.2 !important;
}

.ms-highlight {
  color: #0078d4;
  position: relative;
  display: inline-block;
}

.ms-highlight::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-color: rgba(0, 120, 212, 0.1);
  z-index: -1;
  transform: skew(-15deg);
}

.ms-section-description {
  font-size: 1.25rem;
  text-align: center;
  color: #333;
  max-width: 800px;
  margin: 0 auto 48px !important;
}

.ms-contact-info {
  margin-bottom: 40px;
}

.ms-contact-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.ms-contact-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.ms-contact-icon {
  font-size: 32px;
  color: #0078d4;
  margin-right: 24px;
  margin-top: 4px;
}

.ms-contact-item h4 {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #000;
}

.ms-contact-item p {
  margin-bottom: 4px;
  color: #333;
}

.ms-contact-item a {
  color: #0078d4;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.ms-contact-item a:hover {
  color: #106ebe;
}

.ms-contact-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.ms-contact-form h3 {
  font-size: 2rem;
  margin-bottom: 24px;
  color: #000;
}

.ant-form-item {
  margin-bottom: 24px;
}

.ant-input, .ant-input-textarea {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s ease;
}

.ant-input:hover, .ant-input-textarea:hover {
  border-color: #0078d4;
}

.ant-input:focus, .ant-input-textarea:focus {
  border-color: #0078d4;
  box-shadow: 0 0 0 2px rgba(0, 120, 212, 0.2);
}

.ant-btn-primary {
  background-color: #0078d4;
  border-color: #0078d4;
  height: 48px;
  padding: 0 32px;
  font-size: 1rem;
  border-radius: 24px;
  transition: all 0.3s ease;
}

.ant-btn-primary:hover {
  background-color: #106ebe;
  border-color: #106ebe;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 120, 212, 0.2);
}

.ms-background-shape {
  position: absolute;
  top: -10%;
  right: -5%;
  width: 40%;
  height: 70%;
  background: linear-gradient(45deg, rgba(0, 120, 212, 0.1) 0%, rgba(0, 120, 212, 0.05) 100%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  z-index: 1;
  animation: morphShape 15s ease-in-out infinite;
}

@keyframes morphShape {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  50% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

@media (max-width: 992px) {
  .ms-subscribe-section {
    padding: 80px 0;
  }

  .ms-section-title {
    font-size: 2.75rem !important;
  }
}

@media (max-width: 768px) {
  .ms-contact-form {
    padding: 30px;
  }

  .ms-section-title {
    font-size: 2.25rem !important;
  }

  .ms-section-description {
    font-size: 1.1rem;
  }
}