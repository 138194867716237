.ms-hero-section {
    padding: 120px 0 80px;
    background: linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%);
    overflow: hidden;
    position: relative;
  }
  
  .ms-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
  }
  
  .ms-hero-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  .ms-hero-title {
    font-size: 4rem !important;
    font-weight: 700 !important;
    line-height: 1.1 !important;
    color: #000 !important;
    letter-spacing: -0.02em;
  }
  
  .ms-hero-description {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 0 !important;
    max-width: 600px;
  }
  
  .ms-hero-actions {
    margin-top: 16px;
  }
  
  .ant-btn-primary {
    background-color: #0078d4;
    border-color: #0078d4;
    height: 48px;
    padding: 0 32px;
    font-size: 1rem;
    border-radius: 24px;
    transition: all 0.3s ease;
  }
  
  .ant-btn-primary:hover {
    background-color: #106ebe;
    border-color: #106ebe;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 120, 212, 0.2);
  }
  
  .ant-btn-default {
    height: 48px;
    padding: 0 32px;
    font-size: 1rem;
    border-radius: 24px;
    transition: all 0.3s ease;
  }
  
  .ant-btn-default:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .ms-hero-partners {
    margin-top: 48px;
  }
  
  .ms-partner-logo {
    height: 40px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .ms-partner-logo:hover {
    opacity: 1;
  }
  
  .ms-hero-image-wrapper {
    position: relative;
    z-index: 1;
  }
  
  .ms-hero-image {
    position: relative;
    z-index: 2;
  }
  
  .ms-hero-image img {
    max-width: 120%;
    height: auto;
    border-radius: 16px;
    box-shadow: 0 24px 48px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  }
  
  .ms-hero-image:hover img {
    transform: scale(1.05) rotate(-2deg);
  }
  
  .ms-hero-shape {
    position: absolute;
    top: -20%;
    right: -20%;
    width: 80%;
    height: 140%;
    background: linear-gradient(45deg, #0078d4 0%, #00b294 100%);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    z-index: 1;
    opacity: 0.1;
    animation: morphShape 15s ease-in-out infinite;
  }
  
  @keyframes morphShape {
    0% {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
    50% {
      border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
    }
    100% {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
  }
  
  @media (max-width: 992px) {
    .ms-hero-section {
      padding: 80px 0 40px;
    }
  
    .ms-hero-title {
      font-size: 3rem !important;
    }
  
    .ms-hero-image img {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .ms-hero-title {
      font-size: 2.5rem !important;
    }
  
    .ms-hero-description {
      font-size: 1rem;
    }
  
    .ms-partner-logo {
      height: 30px;
    }
  
    .ms-hero-shape {
      display: none;
    }
  }