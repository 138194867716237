.main-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    padding-top: 64px;
    /* max-height: calc(100vh - 64px); */
  }
