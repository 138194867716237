.tts-dialog-header {
    background: linear-gradient(135deg, #6e8efb 0%, #a777e3 100%);
    color: white;
    padding: 24px;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .tts-icon {
    font-size: 48px;
    margin-right: 16px;
  }
  
  .tts-dialog-title {
    padding: 0;
  }
  
  .tts-close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    color: white;
  }
  
  .tts-dialog-content {
    padding: 24px;
  }
  
  .tts-features {
    margin-top: 16px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 16px;
  }
  
  .tts-dialog-actions {
    padding: 16px 24px;
  }
  
  .tts-button {
    text-transform: none;
    font-weight: 600;
    padding: 8px 24px;
    border-radius: 24px;
    transition: all 0.3s ease;
  }
  
  .tts-button-primary {
    background: linear-gradient(135deg, #6e8efb 0%, #a777e3 100%);
    color: white;
  }
  
  .tts-button-primary:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  
  .tts-button-secondary {
    color: #6e8efb;
    border: 2px solid #6e8efb;
  }
  
  .tts-button-secondary:hover {
    background-color: rgba(110, 142, 251, 0.1);
  }