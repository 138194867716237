.ms-cta-section {
    padding: 100px 0;
    background: linear-gradient(135deg, #0078D4, #106EBE);
    position: relative;
    overflow: hidden;
  }
  
  .ms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;
    z-index: 2;
  }
  
  .ms-cta-content {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .ms-cta-title {
    font-size: 3.5rem !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    margin-bottom: 24px !important;
    line-height: 1.2 !important;
  }
  
  .ms-title-word {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .ms-cta-title .ms-title-word:nth-child(1) { animation-delay: 0.1s; }
  .ms-cta-title .ms-title-word:nth-child(2) { animation-delay: 0.2s; }
  .ms-cta-title .ms-title-word:nth-child(3) { animation-delay: 0.3s; }
  .ms-cta-title .ms-title-word:nth-child(4) { animation-delay: 0.4s; }
  
  .ms-cta-subtitle {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.9);
    max-width: 600px;
    margin: 0 auto !important;
  }
  
  .ms-cta-action {
    display: flex;
    justify-content: center;
  }
  
  .ms-cta-button {
    font-size: 1.1rem !important;
    height: 56px !important;
    padding: 0 40px !important;
    border-radius: 28px !important;
    background-color: #ffffff !important;
    color: #0078D4 !important;
    border: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    transition: all 0.3s ease !important;
  }
  
  .ms-cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
    background-color: #f0f0f0 !important;
  }
  
  .ms-cta-button .anticon {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  .ms-cta-button:hover .anticon {
    transform: translateX(4px);
  }
  
  .ms-cta-background-shape {
    position: absolute;
    top: -50%;
    right: -10%;
    width: 70%;
    height: 200%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    transform: rotate(-15deg);
    z-index: 1;
    animation: rotateShape 20s linear infinite;
  }
  
  @keyframes rotateShape {
    from {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(345deg);
    }
  }
  
  @media (max-width: 768px) {
    .ms-cta-section {
      padding: 80px 0;
    }
  
    .ms-cta-title {
      font-size: 2.5rem !important;
    }
  
    .ms-cta-subtitle {
      font-size: 1.1rem;
    }
  
    .ms-cta-button {
      font-size: 1rem !important;
      height: 48px !important;
      padding: 0 32px !important;
    }
  }