.ms-services-section {
    padding: 120px 0;
    background: linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%);
    overflow: hidden;
    position: relative;
  }
  
  .ms-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    z-index: 2;
  }
  
  .ms-section-title {
    font-size: 3.5rem !important;
    font-weight: 700 !important;
    text-align: center;
    margin-bottom: 16px !important;
    color: #000 !important;
    letter-spacing: -0.02em;
  }
  
  .ms-highlight {
    color: #0078d4;
    position: relative;
    display: inline-block;
  }
  
  .ms-highlight::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: rgba(0, 120, 212, 0.1);
    z-index: -1;
    transform: skew(-15deg);
  }
  
  .ms-section-description {
    font-size: 1.25rem;
    text-align: center;
    color: #333;
    max-width: 800px;
    margin: 0 auto 64px !important;
  }
  
  .ms-services-row {
    margin-bottom: 64px;
  }
  
  .ms-service-card {
    height: 100%;
    padding: 40px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
    position: relative;
  }
  
  .ms-service-card::before {
    content: '';
    position: absolute;
    top: -100px;
    left: -100px;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(0, 120, 212, 0.1) 0%, rgba(255, 255, 255, 0) 70%);
    transition: all 0.5s ease;
  }
  
  .ms-service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
  
  .ms-service-card:hover::before {
    transform: scale(1.5);
  }
  
  .ms-service-icon-wrapper {
    margin-bottom: 24px;
    position: relative;
    z-index: 1;
  }
  
  .service-icon {
    font-size: 48px;
    color: #0078d4;
  }
  
  .ms-service-title {
    font-size: 1.75rem !important;
    font-weight: 600 !important;
    margin-bottom: 16px !important;
    color: #000 !important;
  }
  
  .ms-service-description {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
  }
  
  .ms-cta-row {
    margin-top: 64px;
  }
  
  .ant-btn-primary {
    background-color: #0078d4;
    border-color: #0078d4;
    font-size: 1.1rem;
    height: 56px;
    padding: 0 40px;
    border-radius: 28px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ant-btn-primary:hover {
    background-color: #106ebe;
    border-color: #106ebe;
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 120, 212, 0.2);
  }
  
  .ms-background-shape {
    position: absolute;
    top: -10%;
    right: -5%;
    width: 40%;
    height: 70%;
    background: linear-gradient(45deg, rgba(0, 120, 212, 0.1) 0%, rgba(0, 120, 212, 0.05) 100%);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    z-index: 1;
    animation: morphShape 15s ease-in-out infinite;
  }
  
  @keyframes morphShape {
    0% {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
    50% {
      border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
    }
    100% {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
  }
  
  @media (max-width: 992px) {
    .ms-services-section {
      padding: 80px 0;
    }
  
    .ms-section-title {
      font-size: 2.75rem !important;
    }
  }
  
  @media (max-width: 768px) {
    .ms-section-title {
      font-size: 2.25rem !important;
    }
  
    .ms-section-description {
      font-size: 1.1rem;
    }
  
    .ms-service-card {
      padding: 30px;
    }
  }