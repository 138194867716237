.ms-header {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: all 0.3s ease;
  }

  .ms-header.scrolled {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .ms-header-container {
    max-width: 100%;
    margin: 0 1rem;
    /* padding: 0 1rem; */
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ms-logo {
    text-decoration: none;
  }

  .ms-logo h1 {
    font-size: 3rem;
    font-weight: 600;
    color: #0078d4;
    margin: 0;
  }

  .ms-desktop-menu {
    display: flex;
    /* justify-content: center;
    min-width: 40%; */
  }

  .ms-desktop-menu .ant-menu {
    background: transparent;
    border-bottom: none;
  }

  .ms-desktop-menu .ant-menu-item {
    color: #323130;
  }

  .ms-desktop-menu .ant-menu-item:hover,
  .ms-desktop-menu .ant-menu-item-selected {
    color: #0078d4 !important;
  }

  .ms-desktop-menu .ant-menu-item::after {
    border-bottom: 2px solid #0078d4 !important;
  }

  .ms-header-actions {
    display: flex;
    align-items: center;
  }

  .ms-sign-in-button {
    margin-right: 16px;
    background-color: #0078d4;
    border-color: #0078d4;
  }

  .ms-sign-in-button:hover {
    background-color: #106ebe;
    border-color: #106ebe;
  }

  .ms-menu-button {
    display: none;
  }

  .ms-mobile-menu .ant-drawer-content-wrapper {
    /* width: 80% !important; */
    /* max-width: 300px; */
    width:100%;
  }

  .ms-mobile-menu .ant-drawer-body {
    padding: 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ms-mobile-menu .ant-menu {
    border-right: none;
    background-color: transparent;
    flex-grow: 1;
  }

  .ms-mobile-menu .ant-menu-item {
    margin: 8px 16px;
    height: 54px;
    line-height: 54px;
    border-radius: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ms-mobile-menu .ant-menu-item:hover {
    background-color: rgba(0, 120, 212, 0.08);
  }

  .ms-mobile-menu .ant-menu-item-selected {
    background-color: #0078d4 !important;
  }

  .ms-mobile-menu .ant-menu-item-selected a,
  .ms-mobile-menu .ant-menu-item-selected .ant-menu-item-icon {
    color: white !important;
  }

  .ms-mobile-menu .ant-menu-item a {
    color: #333;
    font-weight: 500;
    font-size: 16px;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  .ms-mobile-menu .ant-drawer-header {
    background-color: #0078d4;
    border-bottom: none;
    padding: 16px 24px;
  }

  .ms-mobile-menu .ant-drawer-title {
    color: white;
    font-size: 22px;
    font-weight: 600;
  }

  .ms-mobile-menu .ant-drawer-close {
    color: white;
    font-size: 20px;
  }

  .ms-mobile-menu .ant-menu-item-icon {
    font-size: 20px;
    margin-right: 12px;
    color: #0078d4;
  }

  .ms-mobile-menu-user {
    padding: 24px;
    background: linear-gradient(135deg, #0078d4, #00a1ff);
    color: white;
    text-align: center;
    margin-bottom: 16px;
  }

  .ms-mobile-menu-user img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid white;
    margin-bottom: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .ms-mobile-menu-user-name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .ms-mobile-menu-user-email {
    font-size: 14px;
    opacity: 0.9;
  }

  .ms-mobile-menu-footer {
    padding: 16px;
    border-top: 1px solid #e8e8e8;
    text-align: center;
    font-size: 14px;
    color: #888;
  }

  @media (max-width: 768px) {
    .ms-desktop-menu {
      display: none;
    }

    .ms-menu-button {
      display: inline-block;
    }
  }
