.blog-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-title {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .page-description {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .featured-post {
    margin-bottom: 30px;
  }
  
  .featured-post img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .blog-post-card {
    margin-bottom: 20px;
  }
  
  .post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .post-meta {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .post-meta .ant-avatar {
    margin-right: 10px;
  }
  
  .post-meta span {
    margin-right: 15px;
  }
  
  .sidebar-section {
    margin-bottom: 20px;
  }
  
  .blog-post-modal .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .blog-post-content img {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .blog-page {
      padding: 10px;
    }
    
    .page-title {
      font-size: 24px;
    }
    
    .page-description {
      font-size: 14px;
    }
    
    .featured-post img {
      height: 200px;
    }
    
    .post-image {
      height: 150px;
    }
    
    .ant-card-body {
      padding: 15px;
    }
    
    .ant-list-item-meta-title {
      font-size: 16px;
    }
    
    .ant-typography {
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .featured-post img {
      height: 150px;
    }
    
    .post-image {
      height: 120px;
    }
    
    .ant-card-body {
      padding: 10px;
    }
    
    .ant-list-item-meta-title {
      font-size: 14px;
    }
    
    .ant-typography {
      font-size: 12px;
    }
    
    .post-meta {
      flex-wrap: wrap;
    }
    
    .post-meta span {
      font-size: 12px;
      margin-right: 10px;
    }
  }

.blog-post-modal .ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.blog-post-modal .ant-modal-header {
  background-color: #f0f2f5;
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 24px;
}

.blog-post-modal .ant-modal-title {
  font-size: 1.5rem;
  color: #1890ff;
}

.blog-post-modal .ant-modal-body {
  padding: 24px;
  max-height: 70vh;
  overflow-y: auto;
}

.blog-post-content h1 {
  font-size: 2rem;
  color: #1890ff;
  margin-bottom: 20px;
}

.blog-post-content h2 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.blog-post-content p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.blog-post-modal .post-meta {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #888;
}

.blog-post-modal .post-meta .ant-avatar {
  margin-right: 10px;
}

.blog-post-modal .post-meta span {
  margin-right: 15px;
}

/* Make the cards clickable */
.featured-post,
.blog-post-card {
  cursor: pointer;
}