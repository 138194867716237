.pricing-page {
  font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
  padding: 60px 20px;
  color: #333;
}

.pricing-header {
  text-align: center;
  margin-bottom: 60px;
}

.pricing-header h1 {
  font-size: 3.5rem;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.pricing-header p {
  font-size: 1.2rem;
  color: #666;
}

.pricing-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.pricing-toggle span {
  margin: 0 15px;
}

.savings {
  background-color: #e6f7ff;
  color: #1890ff;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 10px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 60px;
}

.pricing-card {
  background-color: white;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 300px;
  position: relative;
  overflow: hidden;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.pricing-card.popular {
  border: 2px solid #1890ff;
}

.popular-badge {
  position: absolute;
  top: 20px;
  right: -35px;
  background: #1890ff;
  color: white;
  padding: 8px 40px;
  transform: rotate(45deg);
  font-size: 0.9rem;
  font-weight: bold;
}

.pricing-card h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1890ff;
  margin-bottom: 30px;
}

.price span {
  font-size: 1rem;
  color: #666;
}

.pricing-card ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.pricing-card li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.pricing-card li.disabled {
  color: #999;
}

.pricing-card .anticon {
  margin-right: 10px;
}

.features-comparison, .testimonials, .faq, .cta {
  margin-bottom: 60px;
}

.features-comparison h2, .testimonials h2, .faq h2, .cta h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.testimonial-card {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.testimonial-header .ant-avatar {
  margin-right: 15px;
}

.testimonial-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.testimonial-header p {
  margin: 0;
  color: #666;
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 20px;
}

.testimonial-rating {
  color: #faad14;
}

.cta {
  background-color: #f0f5ff;
  padding: 60px;
  border-radius: 12px;
  text-align: center;
}

@media (max-width: 1024px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 100%;
    max-width: 400px;
  }
  .pricing-page {
    font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
    padding: 60px 20px;
    color: #333;
  }
  
  .pricing-header {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .pricing-header h1 {
    font-size: 3.5rem;
    color: #1a1a1a;
    margin-bottom: 20px;
  }
  
  .pricing-header p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .pricing-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .pricing-toggle span {
    margin: 0 15px;
  }
  
  .savings {
    background-color: #e6f7ff;
    color: #1890ff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    margin-left: 10px;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 60px;
  }
  
  .pricing-card {
    background-color: white;
    border-radius: 12px;
    padding: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 300px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
  
  .pricing-card.popular {
    border: 2px solid #1890ff;
  }
  
  .popular-badge {
    position: absolute;
    top: 20px;
    right: -35px;
    background: #1890ff;
    color: white;
    padding: 8px 40px;
    transform: rotate(45deg);
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .pricing-card h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .price {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1890ff;
    margin-bottom: 30px;
  }
  
  .price span {
    font-size: 1rem;
    color: #666;
  }
  
  .pricing-card ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
  }
  
  .pricing-card li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .pricing-card li.disabled {
    color: #999;
  }
  
  .pricing-card .anticon {
    margin-right: 10px;
  }
  
  .features-comparison, .testimonials, .faq, .cta {
    margin-bottom: 60px;
  }
  
  .features-comparison h2, .testimonials h2, .faq h2, .cta h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .testimonial-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .testimonial-card {
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .testimonial-header .ant-avatar {
    margin-right: 15px;
  }
  
  .testimonial-header h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .testimonial-header p {
    margin: 0;
    color: #666;
  }
  
  .testimonial-text {
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .testimonial-rating {
    color: #faad14;
  }
  
  .cta {
    background-color: #f0f5ff;
    padding: 60px;
    border-radius: 12px;
    text-align: center;
  }
  
  @media (max-width: 1024px) {
    .pricing-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .pricing-card {
      width: 100%;
      max-width: 400px;
    }
  }
}