.app-header {
    background: #ffffff00;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    padding: 0 0px;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 100px;
    margin: 0 auto;
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .user-info:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .username {
    margin-left: 8px;
    font-weight: 500;
    color: #ffffff;
  }
  
  .upgrade-button {
    margin-right: 16px;
  }
  
  /* Responsive styles */
  @media (max-width: 1px) {
    .app-header {
      padding: 0 10px;
    }
  
    .username {
      display: none;
    }
  
    .upgrade-button {
      padding: 0 8px;
    }
  
    .upgrade-button span:last-child {
      display: none;
    }
  }
  
  /* Ant Design overrides */
  .ant-dropdown-menu-item {
    padding: 8px 16px;
  }
  
  .ant-dropdown-menu-item-icon {
    margin-right: 8px;
  }
  
  .ant-btn-link {
    color: #333;
    font-weight: 500;
  }
  
  .ant-btn-link:hover {
    color: #1890ff;
  }